import React from "react";
import { Grid, Typography, Box, ListItemText } from "@mui/material";
// import Masonry from '@mui/lab/Masonry';

//import usecasesVideo from "../assets/images/usecases.mp4";

const boxSx = {
  textAlign: "left",

  margin: "5%",
  //display: "grid",
 // gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
 // gap: "1rem",
};
const titleSx = {
  marginBottom: "20px",
  marginTop: "5%",
  textAlign: "center",
  fontFamily: "Titillium Web",
  color: "#fff",
  fontSize: { xs: "26px", sm: "35px" },
};

// const useCaseSx = {
//   padding: "1rem",
//   borderRadius: "10px",
//   backgroundColor: getRandomGreenColor(),
//   fontFamily: "Titillium Web",
//   fontSize: getRandomFontSize(),
//   color: "#000000",
//   gridRowEnd: `span ${getRandomGridSpan()}`,
// };

// function getRandomPastelColor() {
//   const letters = "89ABCDEF".split(""); // Only include letters in the green spectrum
//     let color = "#";
//     for (let i = 0; i < 3; i++) {
//       color += letters[Math.floor(Math.random() * letters.length)];
//     }
//     return color;
// }
// function getRandomGreenColor() {
//   // Array of predefined green hex color codes
//   const greenColors = [
//     "#afdf7f", "#b5da76", "#a5c442", "#8FBC8F", "#b2d87f",
//     "#5ab55a", "#72c497", "#c3f083", "#90EE90", "#98FB98",
//     "#00FA9A", "#00FF7F", "#7CFC00", "#7FFF00", "#ADFF2F",
//     "#32CD32", "#9ACD32", "#319531", "#6B8E23", "#acf18e",
//     "#a3ca5f", "#69c473", "#8FBC8F", "#90b07e", "#91c291",
//     "#3CB371", "#71e1a2", "#90EE90", "#98FB98", "#00FA9A",
//     "#00FF7F", "#7CFC00", "#7FFF00", "#caff7a", "#32CD32",
//     "#9ACD32", "#228B22", "#bbd781", "#baf580", "#c1e87f"
//   ];

//   // Generate a random index from 0 to the length of the greenColors array - 1
//   const randomIndex = Math.floor(Math.random() * greenColors.length);

//   // Return the green color at the randomly selected index
//   return greenColors[randomIndex];
// }

// function getRandomFontSize() {
//   const fontSizes = ["16px", "18px", "20px", "22px", "24px"];
//   return fontSizes[Math.floor(Math.random() * fontSizes.length)];
// }

// function getRandomGridSpan() {
//   return Math.floor(Math.random() * 3) + 1; // Randomly choose a span between 1 and 3
// }

function UseCases() {
  return (
    <Grid container sx={{ "&.MuiGrid-root": { backgroundColor: "#1f1f1e" } }}>
      <Grid item xs={12} sm={12} md={12} order={{ xs: 2, sm: 1 }}>
    
        <Box component="div" sx={boxSx}>
         
        <Typography variant="h2" sx={titleSx}>
          Use Cases
        </Typography>
          <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={1}
        >
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            sx={{
              "&.MuiGrid-item": { paddingLeft: { xs: "26px", sm: "8px" } },
            }}
          >
            <Box className="tux">
              <span></span>
              <Box className="content">
                <Typography variant="h4" sx={{ fontFamily: "Titillium Web" }}>
                 SSI
                </Typography>
                <p> Decentralized Self Sovereign Identity for Web 3.0 and Web 2.0</p>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            sx={{
              "&.MuiGrid-item": { paddingLeft: { xs: "26px", sm: "8px" } },
            }}
          >
            <Box className="tux">
              <span></span>
              <Box className="content">
                <Typography variant="h4" sx={{ fontFamily: "Titillium Web" }}>
                  DID
                </Typography>
                <p>
                  {" "}
                  W3C COmpliant decentralized identifiers for Algorand and Web 2.0.
                </p>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            sx={{
              "&.MuiGrid-item": { paddingLeft: { xs: "26px", sm: "8px" } },
            }}
          >
            <Box className="tux">
              <span></span>
              <Box className="content">
                <Typography variant="h4" sx={{ fontFamily: "Titillium Web" }}>
                  Verifiable Credentials
                </Typography>
                <p>
                  {" "}
                  W3C compliant Verifiable Credentials and IMS OpenBadges for unlimited various verifiable subjects.
                </p>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            sx={{
              "&.MuiGrid-item": { paddingLeft: { xs: "26px", sm: "8px" } },
            }}
          >
            <Box className="tux">
              <span></span>
              <Box className="content">
                <Typography variant="h4" sx={{ fontFamily: "Titillium Web" }}>
                  Smart Utility NFTs
                </Typography>
                <p> Cash assistance, vouchers, rewards, funds distribution or verified payments</p>
              </Box>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            sx={{
              "&.MuiGrid-item": { paddingLeft: { xs: "26px", sm: "8px" } },
            }}
          >
            <Box className="tux">
              <span></span>
              <Box className="content">
                <Typography variant="h4" sx={{ fontFamily: "Titillium Web" }}>
                  Verifiable GenAI
                </Typography>
                <p>
                  {" "}
                  Use generative AI with provenance, ownership and confidence empowered by Algorand & W3C DIDs and VCs.
                </p>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            sx={{
              "&.MuiGrid-item": { paddingLeft: { xs: "26px", sm: "8px" } },
            }}
          >
            <Box className="tux">
              <span></span>
              <Box className="content">
                <Typography variant="h4" sx={{ fontFamily: "Titillium Web" }}>
                  Token Distribution
                </Typography>
                <p>
                  {" "}
                  Distribute, airdrop or direct send your tokens and NFTs in a verifiable way.
                </p>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            sx={{
              "&.MuiGrid-item": { paddingLeft: { xs: "26px", sm: "8px" } },
            }}
          >
            <Box className="tux">
              <span></span>
              <Box className="content">
                <Typography variant="h4" sx={{ fontFamily: "Titillium Web" }}>
                  Verifiable AI Agents
                </Typography>
                <p>
                  {" "}
                  AI Agents using verifiable credentials for trust, provenance and ownership.
                </p>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            sx={{
              "&.MuiGrid-item": { paddingLeft: { xs: "26px", sm: "8px" } },
            }}
          >
            <Box className="tux">
              <span></span>
              <Box className="content">
                <Typography variant="h4" sx={{ fontFamily: "Titillium Web" }}>
                  Decentralized AuthN 
                </Typography>
                <p>
                  {" "}
                  PKI & DID based, User-less, password-less access, authentication and authorization !
                </p>
              </Box>
            </Box>
          </Grid>
        </Grid>
          {/* <Masonry columns={4} spacing={1}>
            <Box sx={{ ...useCaseSx, fontSize: "1em", backgroundColor: getRandomGreenColor() }}>
              Education & Learning Systems
            </Box>
            <Box sx={{ ...useCaseSx, fontSize: "auto", backgroundColor: getRandomGreenColor() }}>
              Tourism
            </Box>
            <Box sx={{ ...useCaseSx, fontSize: "auto", backgroundColor: getRandomGreenColor() }}>
              Artists
            </Box>
            <Box sx={{ ...useCaseSx, fontSize: "auto", backgroundColor: getRandomGreenColor() }}>
              Fan clubs & social clubs
            </Box>
            <Box sx={{ ...useCaseSx, fontSize: "auto", backgroundColor: getRandomGreenColor() }}>
              DAOs
            </Box>
            <Box sx={{ ...useCaseSx, fontSize: "auto", backgroundColor: getRandomGreenColor() }}>
              GameFi
            </Box>
            <Box sx={{ ...useCaseSx, fontSize: "auto", backgroundColor: getRandomGreenColor() }}>
              Ticketing services
            </Box>
            <Box sx={{ ...useCaseSx, fontSize: "auto", backgroundColor: getRandomGreenColor() }}>
              Law & legal entities
            </Box>
            <Box sx={{ ...useCaseSx, fontSize: "auto", backgroundColor: getRandomGreenColor() }}>
              Entertainment services
            </Box>
            <Box sx={{ ...useCaseSx, fontSize: "auto", backgroundColor: getRandomGreenColor() }}>
              Sport organizations
            </Box>
            <Box sx={{ ...useCaseSx, fontSize: "auto", backgroundColor: getRandomGreenColor() }}>
              Venue & event organizers
            </Box>
            <Box sx={{ ...useCaseSx, fontSize: "auto", backgroundColor: getRandomGreenColor() }}>
              Health & EHR
            </Box>
            <Box sx={{ ...useCaseSx, fontSize: "auto", backgroundColor: getRandomGreenColor() }}>
              Community managers
            </Box>
            <Box sx={{ ...useCaseSx, fontSize: "auto", backgroundColor: getRandomGreenColor() }}>
              Developer Relations
            </Box>

          </Masonry> */}

        </Box>
      </Grid>
      {/* <Grid item xs={12} sm={6} md={6} order={{ xs: 1, sm: 2 }}>
        <Box
          component="video"
          autoPlay
          loop
          muted
          src={usecasesVideo}
          sx={{
            filter: "opacity(0.5) hue-rotate(-19deg)",
            width: { xs: "350px", sm: "500px" },
            height: "auto",
            marginTop: "5%",
            marginBottom: "5%",
          }}
        />
      </Grid> */}
    </Grid>
  );
}

export default UseCases;
