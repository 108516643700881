import React from "react";
import { Grid, Box,Typography } from "@mui/material";
import { Carousel,  } from "react-round-carousel";
import APAC from "../assets/images/gallery/APAC.png";
import CONSENSUS1 from "../assets/images/gallery/CONSENSUS_2023_1.jpeg";
import CONSENSUS2 from "../assets/images/gallery/CONSENSUS_2023_2.jpeg";
import DECIPHER from "../assets/images/gallery/DECIPHER_2022.jpeg";
import NG from "../assets/images/gallery/NG.jpeg";
import KOREA from "../assets/images/gallery/KOREA.jpeg";
import LATAM1 from "../assets/images/gallery/LATAM_1.jpeg";
import LATAM2 from "../assets/images/gallery/LATAM_2.jpeg";
import LATAM3 from "../assets/images/gallery/LATAM_3.jpeg";
import LATAM4 from "../assets/images/gallery/LATAM_4.png";
import MEGA from "../assets/images/gallery/MEGA.jpg";
import NYC1 from "../assets/images/gallery/NFT_NYC_1.jpg";
import NYC2 from "../assets/images/gallery/NFT_NYC_2.jpeg";
import PAKISTAN2 from "../assets/images/gallery/PAKISTAN_2.jpeg";
import PAKISTAN3 from "../assets/images/gallery/PAKISTAN_3.jpeg";
import PAKISTAN4 from "../assets/images/gallery/PAKISTAN_4.jpeg";
import PAKISTAN5 from "../assets/images/gallery/PAKISTAN_5.jpeg";
import ROMA1 from "../assets/images/gallery/ROMA_1.jpeg";
import ROMA2 from "../assets/images/gallery/ROMA_2.jpeg";
import ROMA3 from "../assets/images/gallery/ROMA_3.jpeg";
import ROMA4 from "../assets/images/gallery/ROMA_4.jpg";
import ROMA5 from "../assets/images/gallery/ROMA_5.jpg";

const titleSx = {
  marginTop: "1%",
  marginBottom: "2%",
  fontFamily: "Titillium Web",
  fontSize: { xs: "26px", sm: "35px" },
};
const galleryImages = [
  { alt: "APAC", image: APAC,},
  { alt: "CONSENSUS1", image: CONSENSUS1 },
  { alt: "CONSENSUS2", image: CONSENSUS2 },
  { alt: "LATAM3", image: LATAM3 },
  { alt: "DECIPHER", image: DECIPHER },
  { alt: "PAKISTAN5", image: PAKISTAN5 },
  { alt: "NG", image: NG },
  { alt: "ROMA1", image: ROMA1 },
  { alt: "PAKISTAN3", image: PAKISTAN3 },
  { alt: "KOREA", image: KOREA },
  { alt: "LATAM1", image: LATAM1 },
  { alt: "ROMA5", image: ROMA5 },
  { alt: "LATAM2", image: LATAM2 },
  { alt: "MEGA", image: MEGA },
  { alt: "PAKISTAN4", image: PAKISTAN4 },
  { alt: "ROMA4", image: ROMA4 },
  { alt: "LATAM4", image: LATAM4 },
  { alt: "NYC1", image: NYC1 },
  { alt: "ROMA3", image: ROMA3 },
  { alt: "NYC2", image: NYC2 },
  { alt: "PAKISTAN2", image: PAKISTAN2 },
  { alt: "ROMA2", image: ROMA2 },
];

function Gallery() {
  return (
    <Grid container sx={{ marginTop: "3%",marginBottom:'5%' }} direction="column">
      {/* <Typography sx={titleSx}>
              Contributing Globally
            </Typography>
      <Carousel items={galleryImages} slideOnClick /> */}
    </Grid>
  );
}

export default Gallery;
