import React from "react";
/** @jsxImportSource @emotion/react */

import { Grid, Typography, Box } from "@mui/material";
const titleSx = {
  marginBottom: "20px",
  marginTop: "5%",
  fontFamily: "Titillium Web",
  color: "#fff",
  fontSize: { xs: "26px", sm: "35px" },
};

class Features extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Grid item xs={12} sm={12} md={12} sx={{ padding: "10px" }}>
        <Typography variant="h2" sx={titleSx}>
          Features
        </Typography>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={1}
        >
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            sx={{
              "&.MuiGrid-item": { paddingLeft: { xs: "26px", sm: "8px" } },
            }}
          >
            <Box className="box">
              <span></span>
              <Box className="content">
                <Typography variant="h4" sx={{ fontFamily: "Titillium Web" }}>
                 Web 2.5
                </Typography>
                <p> Permission-less, decentralized and trustless on Web 3.0 and Web 2.0</p>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            sx={{
              "&.MuiGrid-item": { paddingLeft: { xs: "26px", sm: "8px" } },
            }}
          >
            <Box className="box">
              <span></span>
              <Box className="content">
                <Typography variant="h4" sx={{ fontFamily: "Titillium Web" }}>
                  Smart NFTs
                </Typography>
                <p>
                  {" "}
                  Each Plausible package has its own smart ARC3/ARC19 NFT collection.
                </p>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            sx={{
              "&.MuiGrid-item": { paddingLeft: { xs: "26px", sm: "8px" } },
            }}
          >
            <Box className="box">
              <span></span>
              <Box className="content">
                <Typography variant="h4" sx={{ fontFamily: "Titillium Web" }}>
                  DIDs & Verifiable Credentials
                </Typography>
                <p>
                  {" "}
                  DID based , prepaid and MBR ready magic links and QRCodes to distribute VCs
                  for claim.
                </p>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            sx={{
              "&.MuiGrid-item": { paddingLeft: { xs: "26px", sm: "8px" } },
            }}
          >
            <Box className="box">
              <span></span>
              <Box className="content">
                <Typography variant="h4" sx={{ fontFamily: "Titillium Web" }}>
                  Algo Attached / BYOT
                </Typography>
                <p> Attach Algos or any other token to your PLAUS to distribute verifiably!</p>
              </Box>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            sx={{
              "&.MuiGrid-item": { paddingLeft: { xs: "26px", sm: "8px" } },
            }}
          >
            <Box className="box">
              <span></span>
              <Box className="content">
                <Typography variant="h4" sx={{ fontFamily: "Titillium Web" }}>
                  Time Constraint
                </Typography>
                <p>
                  {" "}
                  Start and optional end of validity period.
                </p>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            sx={{
              "&.MuiGrid-item": { paddingLeft: { xs: "26px", sm: "8px" } },
            }}
          >
            <Box className="box">
              <span></span>
              <Box className="content">
                <Typography variant="h4" sx={{ fontFamily: "Titillium Web" }}>
                  Geo Constraint
                </Typography>
                <p>
                  {" "}
                  Allowed and banned countries lists for regulation compliance.
                </p>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            sx={{
              "&.MuiGrid-item": { paddingLeft: { xs: "26px", sm: "8px" } },
            }}
          >
            <Box className="box">
              <span></span>
              <Box className="content">
                <Typography variant="h4" sx={{ fontFamily: "Titillium Web" }}>
                  Generative AI
                </Typography>
                <p>
                  {" "}
                  For PLAUS issuance and Mint a complete set of GenAI tools are available!
                </p>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            sx={{
              "&.MuiGrid-item": { paddingLeft: { xs: "26px", sm: "8px" } },
            }}
          >
            <Box className="box">
              <span></span>
              <Box className="content">
                <Typography variant="h4" sx={{ fontFamily: "Titillium Web" }}>
                  Decentralized WebAuthn 
                </Typography>
                <p>
                  {" "}
                  User-less, password-less authentication to limit claims to one per device!
                </p>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default Features;
