import React, { Component } from "react";
import {
  AppBar,
  Toolbar,
  Box,
  Typography,
  IconButton,
  Tooltip,
} from "@mui/material";
import { Quiz,SupportAgent } from "@mui/icons-material";
import logo from "../assets/images/logo.png";

const logoImgSx = {
  width: "75px",
  cursor: "pointer",
  animation: "animateLogo 4s linear infinite",
};
const titleSx = {
  "&.MuiTypography-root": {
    fontWeight: "bold",
    fontFamily: "Titillium Web,Muli",
    animation: "animateTitle 4s linear infinite",
  },
};
const appbarSx = {
  "&.MuiAppBar-root": {
    backgroundColor: "#1b1c1b",
    color: "#fff",
  },
};
const iconBtnSx = {
  color: "#9aed70",
  "&:hover": {
    color: "#fff",
  },
};

function Header(props) {
  return (
    <>
      <AppBar position="static" elevation={0} sx={appbarSx}>
        <Toolbar>
          <Box component="img" src={logo} sx={logoImgSx} alt="GoPlausible" />
          <Typography variant="h4" className="title" sx={titleSx}>
          GoPlausible
          </Typography>

          <Box sx={{ flexGrow: 1 }} />

          <Tooltip title="Documents" placement="bottom">
            <IconButton
              target="_blank"
              href="  https://goplausible.gitbook.io/goplausible/"
              sx={iconBtnSx}
            >
              <Quiz fontSize="large" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Contact us" placement="bottom">
            <IconButton
              target="_blank"
              href="https://forms.gle/XvRsksAU7kqMEaK17"
              sx={iconBtnSx}
            >
              <SupportAgent fontSize="large" />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
    </>
  );
}

export default Header;
